<template>
  <div class="neyro-app" :class="'neyro-app__' + appThemeClass">
    <div v-if="isAuthorized && $route.name !== 'PublicChat'" class="neyro-app__container">
      <Sidebar />
      <div class="neyro-app__main-view home-page-width">
        <Navbar/>
        <router-view :key="$route.name === 'Chats' || $route.name === 'Home'? '': $route.path" :isAuthorized="isAuthorized" :api="api"></router-view>
      </div>
    </div>

    <div v-if="$route.name === 'PublicChat'" class="neyro-app__main-view">
      <Navbar/>
      <router-view  :api="api"></router-view>
    </div>

    <div v-if="!isAuthorized && $route.name === 'Page'" class="neyro-app__page-view">
      <router-view :api="api"></router-view>
    </div>

    <router-view v-if="!isAuthorized && $route.name !== 'PublicChat' && $route.name !== 'Page'"
                 :key="$route.name === 'Chats' || $route.name === 'Home'? '': $route.path"
                 :isAuthorized="isAuthorized"
                 :api="api"></router-view>

    <div v-show="paymentNotification.success" class="alert alert-success position-absolute end-0 mx-4 payment-alert" role="alert" v-text="paymentNotification.success"></div>
    <div v-show="paymentNotification.error" class="alert alert-danger position-absolute end-0 mx-4 payment-alert" role="alert" v-text="paymentNotification.error"></div>
  </div>
</template>

<script>
  import Navbar from "@/components/reusable/Navbar.vue";
  import {chatPeriods} from "@/data/chats_periods";
  import Sidebar from "@/components/reusable/Sidebar.vue";

  export default {
    components: {Navbar, Sidebar},

    async beforeCreate() {
      await this.$store.dispatch('getUserData');
      await this.$store.dispatch('listenPayments');
      await this.$store.dispatch('getAis');
    },

    data() {
      return {
        chatPeriods: chatPeriods,
      }
    },

    computed: {
      api() {
        return this.$store.state.api;
      },

      paymentNotification() {
        return this.$store.state.paymentNotifications;
      },

      isAuthorized() {
        return this.$store.state.isAuthorized;
      },

      appThemeClass() {
        return this.$store.state.appTheme;
      }
    },
  }
</script>

<style scoped>
.neyro-app__main-view {
  background-color: var(--main-bg-color);
  padding: 16px 28px 0 28px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.neyro-app__container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.neyro-app__page-view {
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
}

.home-page-width {
  width: calc(100% - 260px);
}

.payment-alert {
    animation: ani 0.3s forwards;
    margin-top: 60px;
}

@keyframes ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
}


.neyro-app {
  line-height: 20px;
  color: var(--main-text-color) !important;
  font-weight: 400;
  display: flex;
}
</style>